#invitatie .card  {
    background-image: url(../images/bal3.jpg);
   /* background-image: linear-gradient(90deg, #eef2f5 , #78B497 80%);*/
    color: black;
    background-position: center;
  background-size: cover;

}

#invitatie .card h4 {
 
    text-shadow: 1px 1px 2px rgb(26, 25, 25);
    
}


#img_nasi {
    width: 150px;
    border-radius: 100%;
}

div .gol {
    height: 400px;
}

@media (max-width: 500px) {
    #invitatie .card {
        background-image: url(../images/bal3.jpg);
       /* background-image: linear-gradient(90deg, #eef2f5 , #78B497 80%);*/
    }
    }